import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["lineItemsContainer", "lineItem", "menu", "menuButton"];
  static values = {}

  connect() {
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.updateChecklistItemOrder = this.updateChecklistItemOrder.bind(this);
    const sortableOptions = {
      handle: ".hamburgerMenu",
      animation: 150,
      onSort: this.updateChecklistItemOrder
     }
    const sortable = Sortable.create(this.lineItemsContainerTarget, sortableOptions);
    this.updateChecklistItemOrder();

    document.addEventListener("click", this.handleOutsideClick);
  }

  disconnect() {
    document.removeEventListener("click", this.handleOutsideClick);
  }

  addLineItem(event) {
    this.insertLineItem();
  }

  addLineItemAfter(event) {
    const lineItem = event.currentTarget.closest("[data-checklist-form-target='lineItem']");
    this.insertLineItem(Number(lineItem.dataset.index) + 1);
    this.closeAllMenus();
  }

  insertLineItem(index = Number.MAX_SAFE_INTEGER) {
    const template = document.querySelector("#checklist-line-item-template")
    const newLineItem = template.content.cloneNode(true);
    this.appendChildAtIndex(this.lineItemsContainerTarget, newLineItem, index)
    this.updateChecklistItemOrder();
  }

  appendChildAtIndex(parent, child, index) {
    if (!index || index >= parent.children.length) {
      parent.appendChild(child);
    } else {
      parent.insertBefore(child, parent.children[index]);
    }
  }

  deleteLineItem(event) {
    const lineItem = event.currentTarget.closest("[data-checklist-form-target='lineItem']");

    if (lineItem.querySelector("#checklist_item_id")) {
      lineItem.style.display = "none";
      lineItem.appendChild(this.destroyTag());
      this.lineItemsContainerTarget.appendChild(lineItem);
    } else {
      lineItem.remove();
    }

    this.updateChecklistItemOrder();
  }

  destroyTag() {
    const inputElement = document.createElement("input");
    inputElement.id = "checklist_item_destroy";
    inputElement.type = "hidden";
    inputElement.name = "checklist[checklist_items_attributes][0][_destroy]"
    inputElement.value = true;
    inputElement.readOnly = true;
    return inputElement;
  }

  toggleBody(event) {
    const lineItem = event.currentTarget.closest("[data-checklist-form-target='lineItem']");
    const lineItemBody = lineItem.querySelector("[data-checklist-form-target='lineItemBody']");
    if (lineItemBody.classList.contains("LineItem__body--hidden")) {
      lineItemBody.classList.remove("LineItem__body--hidden");
      event.currentTarget.firstElementChild.classList.remove("fa-plus-square");
      event.currentTarget.firstElementChild.classList.add("fa-minus-square");
    } else {
      lineItemBody.classList.add("LineItem__body--hidden");
      event.currentTarget.firstElementChild.classList.remove("fa-minus-square");
      event.currentTarget.firstElementChild.classList.add("fa-plus-square");
    }
  }

   updateChecklistItemOrder() {
    Array.from(this.lineItemsContainerTarget.children).forEach((lineItem, index) => {
      lineItem.dataset.index = index;
      this.updateNameIndex(lineItem.querySelector('#checklist_item_id'), index);
      this.updateNameIndex(lineItem.querySelector('#checklist_item_name'), index);
      this.updateNameIndex(lineItem.querySelector('#checklist_item_rating_id'), index);
      this.updateNameIndex(lineItem.querySelector('#checklist_item_description'), index);
      this.updateNameIndex(lineItem.querySelector('#checklist_item_destroy'), index);

      const positionElement = lineItem.querySelector('#checklist_item_position');
      this.updateNameIndex(positionElement, index);
      positionElement.value = index;
    });
  }

  updateNameIndex(element, index) {
    if (!element) { return }
    const newName = element.name.replace(/(checklist\[checklist_items_attributes\])\[?\d*\]?(\[[\w_]+\])/, `$1[${index}]$2`);
    element.name = newName;
  }

  closeAllMenus() {
    this.menuTargets.forEach((target) => {
      target.classList.add("Menu__popup--hidden");
    });
  }

  toggleMenu(event) {
    const menu = event.currentTarget.parentNode.querySelector("[data-checklist-form-target='menu']");
    this.menuTargets.forEach((target) => {
      if (target === menu) {
        target.classList.toggle("Menu__popup--hidden");
      } else {
        target.classList.add("Menu__popup--hidden");
      }
    });
  }

  handleOutsideClick(event) {
    const menuButton = this.menuButtonTargets.find((menuButton) => menuButton.contains(event.target))
    if (!menuButton) {
      this.closeAllMenus();
    }
  }
}