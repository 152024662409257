import { Controller } from "@hotwired/stimulus"
import axios from "axios"

// Connects to data-controller="map"
export default class extends Controller {
  static values = {
    coordinates: Array,
    callouts: Array,
    padding: Number
  }

  static CalloutDelegate = class {
    static OFFSET = new DOMPoint(-148, -78);

    // Return a div element and populate it with information from the
    // annotation, including a link to a review site.
    calloutElementForAnnotation(annotation) {
      const landmark = document.createElement("div");
      landmark.className = "landmark";

      if (annotation.data.heading) {
        const heading = landmark.appendChild(document.createElement("h1"));
        heading.textContent = annotation.data.heading;
      }

      if (annotation.data.imageSrc) {
        const link = landmark.appendChild(document.createElement("a"));
        link.href = annotation.data.imageSrc;
        link.target = "_blank";
        link.rel = "noopener";

        const image = link.appendChild(document.createElement("img"));
        image.className = "img-responsive img-thumbnail";
        image.src = annotation.data.imageSrc;
      }

      // Add more content.
      landmark.style.width = "240px";
      landmark.style.height = "100px";

      return landmark;
    }

    calloutAnchorOffsetForAnnotation(_annotation, _element) {
      return this.constructor.OFFSET;
    }
  }

  async connect() {
    this.initializeMap()
  }

  async setupMapKit() {
    console.log("waiting on mapkit...")
    if (!window.mapkit || window.mapkit.loadedLibraries.length === 0) {
      await new Promise(resolve => { window.initMapKit = resolve });
      console.log("mapkit loaded")
      delete window.initMapKit;
    }

    mapkit.init({
      authorizationCallback: function(done) {
        axios.get("/api/v4/mapkit_tokens").then(function(res) {
          var token = res.data;
          done(token);
        });
      }
    });

  }

  async initializeMap() {
    await this.setupMapKit()

    console.log("map initialization reached")
    if (typeof mapkit !== "undefined") {
      console.log("map initialization started")
      console.log(this.coordinatesValue)

      const { center, span } = this.calculateRegion(this.coordinatesValue);

      this.map = new mapkit.Map(this.element, {
        showsZoomControl: true,
        showsMapTypeControl: true,
        showsCompass: mapkit.FeatureVisibility.Hidden,
        showsPointsOfInterest: true,
        showsScale: mapkit.FeatureVisibility.Visible,
        region: new mapkit.CoordinateRegion(new mapkit.Coordinate(center.lat, center.lng), new mapkit.CoordinateSpan(span.latDelta, span.lngDelta)),
      });


      this.addAnnotations();

      console.log("map initialization complete")
    }
  }

  calculateRegion(coordinates) {
    if (coordinates.length === 0) return {};

    let minLat = coordinates[0].lat;
    let maxLat = coordinates[0].lat;
    let minLng = coordinates[0].lng;
    let maxLng = coordinates[0].lng;

    coordinates.forEach(coord => {
      if (coord.lat < minLat) minLat = coord.lat;
      if (coord.lat > maxLat) maxLat = coord.lat;
      if (coord.lng < minLng) minLng = coord.lng;
      if (coord.lng > maxLng) maxLng = coord.lng;
    });

    const center = {
      lat: (minLat + maxLat) / 2,
      lng: (minLng + maxLng) / 2
    };

    const latDelta = maxLat - minLat;
    const lngDelta = maxLng - minLng;

    // Add a small padding to the span to ensure all points are within view
    const padding = this.paddingValue || 0.04;

    const span = {
      latDelta: latDelta + this.paddingValue,
      lngDelta: lngDelta + this.paddingValue
    };

    return { center, span };
  }

  addAnnotations() {
    if (!this.calloutsValue) {
      return;
    }

    const calloutDelegate = new this.constructor.CalloutDelegate;

    this.calloutsValue.forEach((callout) => {
      // Create an annotation with a link to be displayed in the callout.
      const annotation = new mapkit.MarkerAnnotation(
        new mapkit.Coordinate(callout.lat, callout.lng),
        {
          callout: (callout.heading || callout.imageSrc) && calloutDelegate,
          data: callout,
          title: callout.label,
          color: callout.color || null,
        }
      );

      this.map.addAnnotation(annotation);
    });
  }
}
