import * as SignaturePad from "signature_pad"
// Initializes canvas so user can provide signature
//
// Due to complexities with getting it working, this
// currently passes the signature data back thru the
// form encoded in the Data URI format inside of a
// hidden_field on the inspection_item
//
// In future iteration, this would ideally upload
// the image/png to S3 to be processed off dyno.
$(function() {
  $(".js-signature-button").click(function(e) {
    var signatureContainer = $(this).parent();
    var signatureModalSelector = $(this).data("target");

    var wrapper = document.querySelector(signatureModalSelector),
      canvas = wrapper.querySelector(".js-signature"),
      signaturePad;

    signaturePad = new SignaturePad(canvas);

    // Listeners to modal buttons
    $(signatureContainer).find(".js-save-signature").click(function(e) {
      if (signaturePad.isEmpty()) {
        // noop - we do not ask user to enter signature
      } else {
        // Save Signature to hidden field
        var signatureDataURI = signaturePad.toDataURL('image/png');
        $(signatureContainer).find("input").val(signatureDataURI);

        // displays checkmark indicator
        $(signatureContainer).find("i").removeClass("hidden");
      }
      $(signatureModalSelector).modal("hide");
    });

    // Clears prior signature data and removes checkmark indicator
    $(signatureContainer).find(".js-clear-signature").click(function(e) {
      $(signatureContainer).find("input").val("");
      $(signatureContainer).find("i").addClass("hidden");
      signaturePad.clear();
    });
  });
})
