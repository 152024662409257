import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    enabled: Boolean,
    hasActiveVisit: Boolean,
    redirectPath: String,
    structureId: Number
  }

  connect() {
    if (this.enabledValue) {
      if (this.hasActiveVisitValue) {
        window.alert("Visit Tracking\n\nYou are already checked-in.")
      } else {
        this.showPrompt();
      }
    }
  }

  showPrompt() {
    const title = "Visit Tracking"
    const message = "Would you like to check-in to this area?"

    const userConfirmed = window.confirm(`${title}\n\n${message}`)

    if (userConfirmed) {
      this.checkIn();
    }
  }

  checkIn() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.buildAndSubmitForm(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          console.warn("Error getting location:", error);
          this.buildAndSubmitForm();
        }
      );
    } else {
      console.warn("Geolocation is not supported by this browser.");
      this.buildAndSubmitForm();
    }
  }

  buildAndSubmitForm(latitude = null, longitude = null) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const form = document.createElement('form');
    form.method = "POST";
    form.action = this.redirectPathValue;
  
    const csrfField = document.createElement('input');
    csrfField.type = 'hidden';
    csrfField.name = 'authenticity_token';
    csrfField.value = csrfToken;
    form.appendChild(csrfField);
  
    const structureField = document.createElement('input');
    structureField.type = 'hidden';
    structureField.name = 'structure_id';
    structureField.value = this.structureIdValue;
    form.appendChild(structureField);

    if (latitude && longitude) {
      const latitudeField = document.createElement('input');
      latitudeField.type = 'hidden';
      latitudeField.name = 'latitude';
      latitudeField.value = latitude;
      form.appendChild(latitudeField);
  
      const longitudeField = document.createElement('input');
      longitudeField.type = 'hidden';
      longitudeField.name = 'longitude';
      longitudeField.value = longitude;
      form.appendChild(longitudeField);
    }
  
    document.body.appendChild(form);
    form.submit();
  }
}
