const moment = require('moment');

$(function() {

  ///////////////////////////////
  //         SELECTORS         //
  ///////////////////////////////

  const $inspectionForm = $("#js-inspection-form");
  const $inspectionFormSubmitButton = $("#js-inspection-form-submit-button");

  const $endedAtField = $inspectionForm.find("#js-ended-at-field");
  const $rangeChoicePositionFields = $inspectionForm.find(".js-rating-choice-position-field")


  ///////////////////////////////
  //         HANDLERS          //
  ///////////////////////////////

  // NOTE: We listen for click of the submission button rather than form
  //   submission, as triggering a form submission inside a form submission
  //   listener would cause an infite loop.
  $inspectionFormSubmitButton.on("click", function(event) {
    event.preventDefault();
    setEndedAtField();
    $inspectionForm.submit();
  })

  $rangeChoicePositionFields.on("change", function() {
    const selectedValue = $(this).val();

    const $dataContainer = $(this).closest(".js-data-container");
    const $inputContainer = $(this).closest(".form-group");

    const rangeChoiceType = $dataContainer.data("range-choice-type");
    const rangeChoiceOptionsData = $dataContainer.data("range-choice-options");

    const selectedOptionData = rangeChoiceOptionsData[selectedValue];

    // Universal Fields:
    // ---------------------
    const $deficientField = $inputContainer.find("#inspection_inspection_items__deficient");
    $deficientField.val(selectedOptionData["deficient"]);

    const $labelField = $inputContainer.find("#inspection_inspection_items__range_choice_label");
    $labelField.val(selectedOptionData["label"]);

    // Type Specific Fields:
    // ---------------------
    if (rangeChoiceType == "percentage") {
      const $scoreField = $inputContainer.find("#inspection_inspection_items__score");
      $scoreField.val(selectedOptionData["decimal_score"]);
    }
    else if (rangeChoiceType == "points") {
      const $pointsField = $inputContainer.find("#inspection_inspection_items__points");
      $pointsField.val(selectedOptionData["points"]);
    }
  })

  ///////////////////////////////
  //         FUNCTIONS         //
  ///////////////////////////////

  function setEndedAtField() {
    $endedAtField.val(moment().format());
  }

});
