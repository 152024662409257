import { Controller } from "@hotwired/stimulus"
import * as SignaturePad from "signature_pad"

// Evolution of signature.js, for use by ChecklistSubmissions.
// Client-side uploading of signature images to S3.
export default class extends Controller {
  static targets = ["container", "modal", "input", "canvas", "signatureIndicator",
    // Distributed Attachments Management
    "uploadedAt", "signedUploadUrl", "filename", "mimeType", "byteSize", "checksum", "lockVersion"
  ]
  static values = { filename: String }
  static FILENAME = 'signature.png'

  connect() {
    this.filenameValue = this.constructor.FILENAME
  }

  open() {
    this.pad = new SignaturePad(this.canvasTarget)

    // Load existing signature
    // Note: sizing is off; haven't investigated
    this.pad.fromDataURL(this.inputTarget.value)
  }

  clear() {
    this.clearHiddenFields()
    this.signatureIndicatorTarget.classList.add("hidden")
    this.pad.clear()
  }

  async save() {
    if (this.pad.isEmpty()) {
      // TODO: pad allows saving nothing
      this.signatureIndicatorTarget.classList.add("hidden")
    } else {
      try {
        var signatureDataURI = this.pad.toDataURL('image/png')
        const preparePresignedPostResponse = await this.requestPresignedPost()
        await this.uploadPresignedPhoto(signatureDataURI, preparePresignedPostResponse)

        this.setHiddenFields(preparePresignedPostResponse['object-url'], signatureDataURI.length)
        this.inputTarget.value = signatureDataURI
        this.signatureIndicatorTarget.classList.remove("hidden")
      } catch (error) {
        alert('Error processing signature. Please try again.')
      }
    }
    $(this.modalTarget).modal('hide')
  }

  async requestPresignedPost() {
    // TODO: randomize name

    try {
      const response = await fetch('/api/v4/uploads/presigned_posts', {
        method: 'POST',
        body: JSON.stringify({ file_data: [{ name: this.filenameValue }] }),
        headers: { "Content-Type": "application/json" },
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json()
      if (!data.presignedPosts || !data.presignedPosts[this.filenameValue]) {
        throw new Error('Invalid response structure or missing presigned posts data')
      }

      return data.presignedPosts[this.filenameValue]
    } catch (error) {
      console.error("Error fetching presigned post:", error)
      throw error
    }
  }

  async uploadPresignedPhoto(signatureData, presignedObject) {
    const formData = new FormData()

    Object.entries(presignedObject.fields).forEach(([key, value]) => {
      formData.append(key, value)
    })

    formData.append('file', this.base64ToFile(signatureData))

    try {
      const response = await fetch(presignedObject.url, {
        method: 'POST',
        body: formData
      })
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error uploading presigned photo:", error)
      throw error
    }
  }

  base64ToFile(base64String) {
    const byteString = atob(base64String.split(",")[1]) // Decode base64 string
    const mimeType = base64String.match(/data:([^;]+);base64/)[1]

    const arrayBuffer = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) {
      arrayBuffer[i] = byteString.charCodeAt(i)
    }

    return new File([arrayBuffer], this.filenameValue, { type: mimeType })
  }

  setHiddenFields(signedUrl, byteSize) {
    this.setFieldValue(this.uploadedAtTarget, Date.now())
    this.setFieldValue(this.signedUploadUrlTarget, signedUrl)
    this.setFieldValue(this.filenameTarget, this.filenameValue)
    this.setFieldValue(this.mimeTypeTarget, 'image/png')
    this.setFieldValue(this.byteSizeTarget, byteSize)
    this.setFieldValue(this.checksumTarget, 'not-calculated')
    this.setFieldValue(this.lockVersionTarget, 0)
  }

  setFieldValue(target, value) {
    target.value = value
    target.disabled = false
  }

  clearHiddenFields() {
    this.containerTarget.querySelectorAll('input').forEach((element) => {
      element.value = null
      element.disabled = true
    })
  }
}
