// Prompts for geolocation if #latitude element is found, without a value.
// Used on web for inspection_forms and checklist_submissions
$(function() {
  if($("#latitude").length > 0) {
    navigator.geolocation.getCurrentPosition(handle_geolocation_query);
  }
});

function handle_geolocation_query(position) {
  $("#latitude").val(position.coords.latitude);
  $("#longitude").val(position.coords.longitude);

  $("#geolocation-not-found-message").addClass("hidden");
  $("#geolocation-success-message").removeClass("hidden");
}
